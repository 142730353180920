import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import appconfigSlice from "./slices/appconfigSlice";
import loginconfigSlice from "./slices/loginconfigSlice";
import searchFilterSlice from "./slices/searchFilterSlice";
import bookingSlice from "./slices/bookingSlice";
import requestSlice from "./slices/requestSlice";

const reducers = combineReducers({
    appconfig: appconfigSlice,
    loginconfig: loginconfigSlice,
    searchfilters: searchFilterSlice,
    booking: bookingSlice,
    requestAPI: requestSlice,
});

const persistConfig = {
    key: "gt-web:rootApp",
    storage,
    whitelist: ["loginconfig", "appconfig", "searchfilters"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;
