import React, {useEffect, useState} from "react";
import BedFlightIcon from "../../../assets/images/icons/bedflight-icon.svg";
import "./style.scss";
import {useDispatch, useSelector} from "react-redux";
import {getBooking} from "../../../redux/slices/bookingSlice";
import {
    getCurrencyBadge,
    getFlightsParams,
    getThreeFilterToShow,
} from "../../../helpers/common";
import {useTranslation} from "react-i18next";
import {
    getAppConfig,
    setActivityIndicator,
} from "../../../redux/slices/appconfigSlice";
import PriceFlipper from "../../PriceFlipper";
import {getSearchFilters} from "../../../redux/slices/searchFilterSlice";
import {getLoginConfig} from "../../../redux/slices/loginconfigSlice";
import {GetFlightDetailsService} from "../../../services/HotelDetailServices";
import {toast} from "react-toastify";
import "react-step-progress-bar/styles.css";
import {ProgressBar, Step} from "react-step-progress-bar";
import CatImage from "../../../assets/images/genius-footer-logo.svg";
import PropTypes from "prop-types";

const DeatilsCard = ({steps, setSteps}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const booking = useSelector(getBooking);
    const {room, flight} = booking;
    const searchfilters = useSelector(getSearchFilters);
    const loginConfig = useSelector(getLoginConfig);
    const appConfig = useSelector(getAppConfig);

    // const [steps, setSteps] = useState([
    //     {name: t("chooseRoom"), completed: false, icon: RoomIcon},
    //     {name: t("chooseFlight"), completed: false, icon: FlightIcon},
    //     {name: t("confirmBooking"), completed: false, icon: ConfirmIcon},
    // ]);

    const getPrice = (price) => Number(price?.value).toFixed(0) || 0;
    const [price, setPrice] = useState(
        getPrice(flight?.price?.packageTotalPrice)
    );
    const [oldPrice, setOldPrice] = useState(
        getPrice(flight?.price?.packageTotalPrice)
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setSteps(prevSteps => prevSteps.map((step, index) => {
                if (index === 0 || index === 1) {
                    return {...step, completed: true};
                } else {
                    return step;
                }
            }));
        }, 1000);

        return () => clearInterval(interval);
    }, []); // Empty dependency array to run the effect only once on mount

    useEffect(() => {
        setOldPrice(price);
        setPrice(getPrice(flight?.price?.packageTotalPrice));
    }, [flight]);

    let filters = getThreeFilterToShow(room, t, flight);

    const handleOnBooking = () => {
        setSteps(prevSteps => prevSteps.map(m => {
            return {...m, completed: true}
        }));
        const params = getFlightsParams(
            room,
            searchfilters,
            loginConfig,
            booking,
            appConfig,
            searchfilters?.selectedHotel?.id,
            searchfilters?.selectedHotel?.guidForBooking
        );
        const BaseUrl = window.location.origin;
        const newTab = window.open(`${BaseUrl}/fetching`, '_blank');

        try {
            params.GetFinalExpediaUrl = true;
            params.transaction_id = appConfig?.transaction_id;

            // console.log(JSON.stringify(params), "params for getting more flights");

            dispatch(setActivityIndicator(true));
            GetFlightDetailsService(params)
                .then((_flightDetails) => {
                    dispatch(setActivityIndicator(false));

                    // console.log({_flightDetails});

                    if (
                        _flightDetails?.warnings?.length &&
                        _flightDetails?.warnings[0]?.code === "400.19"
                    ) {
                        toast.info(_flightDetails?.warnings[0]?.description);
                        return;
                    }

                    const externalUrl = _flightDetails?.offers[0]?.links?.webDetails?.href;
                    if (newTab) {
                        newTab.location.href = externalUrl;
                    }
                })
                .catch(() => {
                    dispatch(setActivityIndicator(false));
                    if (newTab) {
                        newTab.close();
                    }
                });
        } catch (e) {
            if (newTab) {
                newTab.close();
            }
        }
    };

    function calculatePercentage() {
        if (steps[2]?.completed) {
            return 100;
        } else if (steps[1]?.completed) {
            return 50;
        } else if (steps[0]?.completed) {
            return 0;
        } else return 0;
    }

    return (
        <>
            <div className="desktop-price-section">
                <div className="container">
                    <div className="bluebox-bg">
                        <div className="price-info-holder">
                            <div className="price">
                                {Number(flight?.price?.savings?.value) > 0 && (
                                    <div className="total desktop">
                                        {t("Total")}{" "}
                                        <em>
                                            {Number(
                                                flight?.price?.standAloneTotalPrice?.value
                                            ).toFixed(0)}
                                        </em>
                                    </div>
                                )}
                                <div className="flipper_box d-flex align-items-center gap-2">
                                    <span
                                        className="currency-icon">{getCurrencyBadge(flight?.price?.packageTotalPrice?.currency)}</span>

                                    <PriceFlipper price={price} oldPrice={oldPrice}/>
                                </div>

                                <div className="floater-total-taxes">

                                    {flight?.price?.hotelMandatoryFees && (
                                        <div className="text-white">
                                        <span>
                                            <strong>
                                                {`+${getCurrencyBadge(
                                                    flight?.price?.hotelMandatoryFees?.currency
                                                )}${Number(
                                                    flight?.price?.hotelMandatoryFees?.value
                                                ).toFixed(2)}`}
                                            </strong>
                                        </span>{" "}
                                            <span className="local-taxes desktop">
                                                {t("LocalText")}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="tags-total">
                                <div className="text-center">
                                    {filters?.map((f, i) => (
                                        <div key={i} className="badge">
                                            {f?.title}
                                        </div>
                                    ))}
                                </div>
                                <div className="steps-box">
                                    <ProgressBar
                                        percent={calculatePercentage()}
                                        height={3}
                                        filledBackground="linear-gradient(to right, #007bff, #007bff)"
                                        style={{marginBottom: "10px"}}
                                    >
                                        {steps?.map((step, index) => (
                                            <Step key={index} index={index} transition="scale">
                                                {() => (
                                                    <div className={"step"}>
                                                        <div
                                                            className={`circle ${
                                                                step?.completed ? "active" : ""
                                                            }`}
                                                        >
                                                            <img
                                                                className={"step-icon"}
                                                                src={step?.icon}
                                                                alt={"step-icon"}
                                                            />
                                                        </div>
                                                        <p className={"step-label"}>{step?.name}</p>
                                                    </div>
                                                )}
                                            </Step>
                                        ))}
                                    </ProgressBar>
                                </div>
                            </div>

                            <div className="action">
                                <div>
                                    <img className="genius-icon" src={CatImage} alt="genius-icon"/>
                                </div>
                                <a onClick={handleOnBooking}>
                                    {t("DetailCard.IWantThisDeal")}
                                </a>
                                <div className="average-price">
                                    {t("AveragePP")}
                                    <div className="d-flex">
                                        <div className="currency">
                                            {getCurrencyBadge(
                                                flight?.price?.averageCostPerPerson?.currency
                                            )}
                                        </div>
                                        <div className="amount">
                                            {Number(
                                                flight?.price?.averageCostPerPerson?.value
                                            ).toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobiles-price-section">
                <div className="container">
                    <div className="bluebox-bg">
                        <div className="tags-total">
                            <div>
                                <div>
                                    {filters?.map((f, i) => (
                                        <div key={i} className="badge">
                                            {f?.title}
                                        </div>
                                    ))}
                                </div>
                                {Number(flight?.price?.savings?.value) > 0 && (
                                    <div className="total mobile">
                                        {t("Total")}{" "}
                                        <em>
                                            {Number(
                                                flight?.price?.standAloneTotalPrice?.value
                                            ).toFixed(0)}
                                        </em>
                                    </div>
                                )}
                            </div>
                            <div className="average-price">
                                {t("AveragePP")}
                                <div className="d-flex">
                                    <div className="currency">
                                        {getCurrencyBadge(
                                            flight?.price?.averageCostPerPerson?.currency
                                        )}
                                    </div>
                                    <div className="amount">
                                        {Number(flight?.price?.averageCostPerPerson?.value).toFixed(
                                            2
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="price-info-holder">
                            <div className="price">
                                <div className="flipper_box d-flex align-items-center gap-2">
                                    {getCurrencyBadge(flight?.price?.packageTotalPrice?.currency)}

                                    <PriceFlipper price={price} oldPrice={oldPrice}/>
                                </div>

                                <div className="floater-total-taxes">
                                    {Number(flight?.price?.savings?.value) > 0 && (
                                        <div className="total desktop">
                                            {t("Total")}{" "}
                                            <em>
                                                {Number(
                                                    flight?.price?.standAloneTotalPrice?.value
                                                ).toFixed(0)}
                                            </em>
                                        </div>
                                    )}

                                    {flight?.price?.hotelMandatoryFees && (
                                        <div className="text-white">
                                            <span>
                                                <strong>
                                                {`+${getCurrencyBadge(
                                                    flight?.price?.hotelMandatoryFees?.currency
                                                )} ${Number(
                                                    flight?.price?.hotelMandatoryFees?.value
                                                ).toFixed(2)}`}
                                                </strong>
                                            </span>{" "}
                                            <span className="local-taxes desktop">
                                                {t("LocalText")}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="action">
                                <a onClick={handleOnBooking}>
                                    {t("DetailCard.IWantThisDeal")}
                                    <img
                                        src={BedFlightIcon}
                                        className="bed-icon"
                                        alt={"bed-icon"}
                                    />
                                </a>
                            </div>
                        </div>
                        {flight?.price?.hotelMandatoryFees && (
                            <div className="local-taxes forSmaller">{t("LocalText")}</div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
        ;
};

DeatilsCard.propTypes = {
    steps: PropTypes.object,
    setSteps: PropTypes.func
}

export default React.memo(DeatilsCard);
