import React from 'react';
import "./shareStyles.scss";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon,
} from "react-share";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const ShareBranchLink = ({shortLink}) => {
    const {t} = useTranslation();
    const title = t("TopBar.Share_Trip_Text");

    return (
        <div className="Demo__container">
            <div className="Demo__some-network">
                <FacebookShareButton
                    url={shortLink}
                    className="Demo__some-network__share-button"
                >
                    <FacebookIcon size={32} round/>
                </FacebookShareButton>
            </div>

            <div className="Demo__some-network">
                <FacebookMessengerShareButton
                    url={shortLink}
                    appId="521270401588372"
                    className="Demo__some-network__share-button"
                >
                    <FacebookMessengerIcon size={32} round/>
                </FacebookMessengerShareButton>
            </div>

            <div className="Demo__some-network">
                <TwitterShareButton
                    url={shortLink}
                    title={title}
                    className="Demo__some-network__share-button"
                >
                    <XIcon size={32} round/>
                </TwitterShareButton>
            </div>

            <div className="Demo__some-network">
                <TelegramShareButton
                    url={shortLink}
                    title={title}
                    className="Demo__some-network__share-button"
                >
                    <TelegramIcon size={32} round/>
                </TelegramShareButton>
            </div>

            <div className="Demo__some-network">
                <WhatsappShareButton
                    url={shortLink}
                    title={title}
                    separator=":: "
                    className="Demo__some-network__share-button"
                >
                    <WhatsappIcon size={32} round/>
                </WhatsappShareButton>
            </div>

            <div className="Demo__some-network">
                <LinkedinShareButton
                    url={shortLink}
                    className="Demo__some-network__share-button"
                >
                    <LinkedinIcon size={32} round/>
                </LinkedinShareButton>
            </div>

            <div className="Demo__some-network">
                <EmailShareButton
                    url={shortLink}
                    subject={title}
                    // body="body"
                    className="Demo__some-network__share-button"
                >
                    <EmailIcon size={32} round/>
                </EmailShareButton>
            </div>
        </div>
    );
};

ShareBranchLink.propTypes = {
    shortLink: PropTypes.string,
}

export default ShareBranchLink;