import moment from "moment";
import {removeOffer, setModals, setOffers,} from "../redux/slices/appconfigSlice";
import {
    setPaginatedRoomFlights,
    setPaginatedRoomTypes,
    setSelectedHotel,
    setSelectedHotelDetail,
} from "../redux/slices/searchFilterSlice";
import {AddToFavoriteList, RemoveFromFavoriteList,} from "../services/FavoriteHotelService";
import {resetBookingState, setBothFlight, setHotelBooking, setHotelRoom,} from "../redux/slices/bookingSlice";

export const _EMAIL_REG_EXP = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;

/**
 * Replaces the value of a specific query parameter in the URL and returns the updated URL.
 * @param {string} key - The query parameter key to replace.
 * @param {string} value - The new value for the query parameter.
 * @returns {string} - The updated URL.
 */
export const replaceQueryParam = (key, value) => {
    // Parse the current URL and its query parameters
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    // Replace the value of the specified query parameter
    params.set(key, value);

    // Construct the updated URL
    return params.toString();
}

export const getQueryParamValue = (key) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    // Get the value of the specified query parameter
    return params.get(key);
};

export const getCurrencyBadge = (currency) => {
    let badge = "$";
    switch (currency) {
        case "EUR":
            badge = "€";
            break;

        case "USD":
            badge = "$";
            break;

        case "GBP":
            badge = "£";
            break;

        default:
            break;
    }
    return badge;
};

export const getCurrencyName = (currency) => {
    let name = "Euro";
    switch (currency) {
        case "EUR":
            name = "Euro";
            break;

        case "USD":
            name = "Dollar";
            break;

        // case 'GBP':
        //     name = 'Pound';
        //     break;

        default:
            break;
    }
    return name;
};

/**
 * Get random package from array.
 * @param {array of pacagkes} items
 * @returns package Item as object
 */
export const getRandomPackageFromArray = (items) => {
    return items[Math.floor(Math.random() * items.length)];
};

export const paginateData = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const getPager = (totalItems, currentPage = 1, pageSize = 10, maxPages = 10) => {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage, endPage;
    if (totalPages <= maxPages) {
        // less than or equal to maxPages total pages so show all
        startPage = 1;
        endPage = totalPages;
    } else {
        // more than maxPages total pages so calculate start and end pages
        let halfPages = Math.floor(maxPages / 2);
        if (currentPage <= halfPages + 1) {
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + halfPages >= totalPages) {
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            startPage = currentPage - halfPages;
            endPage = currentPage + halfPages;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages,
    };
};


export const getListingItemFilters = (offer, t, flight) => {
    let filters = [];
    if (offer && offer?.roomTypes?.length) {
        const {checkInDate, checkOutDate} = getCheckInOutDatesFromFlight(flight);
        // Created start and end date
        // const checkInDate = checkInDate;
        // const checkOutDate = checkOutDate;
        const firstChip = {
            id: 1,
            title: `${moment.parseZone(checkInDate).format("MMM DD")}-${moment.parseZone(
                checkOutDate
            ).format("DD")}`,
        };
        filters.push(firstChip);

        // Creating room and person count from URL
        const objUrl = new URL(offer?.roomTypes[0]?.links?.webDetails?.href);
        if (objUrl) {
            const searchparams = new URLSearchParams(objUrl?.search);
            let total = 0;
            const roomsCount = searchparams.get("NumRoom");
            const adultsCount = searchparams.get('NumAdult')?.split('|').reduce((previous, current) => previous + Number(current.split(':')[1]), 0);
            const childCount = searchparams.get('NumChild')?.split('|').reduce((previous, current) => previous + Number(current.split(':')[1]), 0);

            if (adultsCount) {
                total = Number(total) + Number(adultsCount);
            }
            if (childCount) {
                total = Number(total) + Number(childCount);
            }
            const secondChip = {id: 2, title: `${total} pers / ${roomsCount} rm`};
            filters.push(secondChip);
        }

        // Creating days of staying
        let nights = nightDifferenceCountForFlightsDate(checkInDate, checkOutDate);
        nights = Number(nights);
        const thirdChip = {
            id: 3,
            title: `${nights} ${nights > 1 ? t("nightsRange") : t("nightRange")}`,
        };
        filters.push(thirdChip);
    } else {
        // Created start and end date
        const checkInDate = offer?.departureDate;
        const checkOutDate = offer?.returnDate;
        const firstChip = {
            id: 1,
            title: `${moment.parseZone(checkInDate).format("MMM DD")}-${moment.parseZone(
                checkOutDate
            ).format("DD")}`,
        };
        filters.push(firstChip);

        // Creating room and person count from URL
        const roomsCount = 1;
        const adultsCount = offer?.adults;
        const childCount = offer?.children;
        let total = 0;
        if (adultsCount) {
            total = Number(total) + Number(adultsCount);
        }
        if (childCount) {
            total = Number(total) + Number(childCount);
        }
        const secondChip = {id: 2, title: `${total} pers / ${roomsCount} rm`};
        filters.push(secondChip);

        // Creating days of staying
        const nights = nightDifferenceCountForFlightsDate(checkInDate, checkOutDate);
        // nights = Number(nights);
        const thirdChip = {
            id: 3,
            title: `${nights} ${nights > 1 ? t("nightsRange") : t("nightRange")}`,
        };
        filters.push(thirdChip);
    }

    return filters;
};

export const nightDifferenceCountForFlightsDate = (start, end) => {
    const checkInDate = moment.parseZone(start).format('YYYY-MM-DD');
    const checkOutDate = moment.parseZone(end).format('YYYY-MM-DD');

    return moment(checkOutDate).diff(moment(checkInDate), 'days');
};

export const navigateToDetailPage = (
    offer,
    dispatch,
    loginConfig,
    searchfilters,
    navigate,
    page,
    replace = null,
    isFavorite = false,
    // showFlights = false,
) => {
    // if (!showFlights) {
    // dispatch(resetBookingState());
    //     dispatch(setSelectedHotel(offer));
    //     dispatch(setSelectedHotelDetail(offer?.roomTypes));
    //     dispatch(setRoomFlights([offer?.objHotel?.newPackagedOffer?.flightOffer]));
    // }

    localStorage.setItem('roomFetched', 'false');
    localStorage.setItem('flightFetched', 'false');

    const {adults, childs, infants} = getTravelersData(searchfilters);

    const flightData = offer?.objHotel?.newPackagedOffer?.flightOffer; // Selected flight from redux

    // departure ticket data
    const departureSegmentId = flightData?.segmentIds[0];
    const departureFlightSegment = flightData?.segments?.filter(
        (f) => f.segmentId === departureSegmentId
    )[0];
    const departureFlight = departureFlightSegment?.legs[0];
    // End of data

    // Arrival ticket data
    const arrivalSegmentId =
        flightData?.segmentIds[flightData?.segmentIds?.length - 1];
    const arrivalFlightSegment = flightData?.segments?.filter(
        (f) => f.segmentId === arrivalSegmentId
    )[0];
    const returnFlight = arrivalFlightSegment?.legs[0];
    // End of data


    // console.log({offer})
    const params = {
        hotelId: offer?.id,
        upSell: false,
        Adults: adults,
        Children: childs,
        Infants: infants,
        Locale: loginConfig.locale.code,
        fav: isFavorite,
        // showFlights: showFlights,
        roomKey: offer?.roomTypes[0]?.roomKey,
        GUID: offer?.guidForBooking,
        offerId: offer?.objHotel?.newPackagedOffer?.flightOffer?.offerId || null,
        NumberOfStops: searchfilters?.numberOfStops,
        Departure: departureFlight?.departureAirport?.code,
        Destination: returnFlight?.departureAirport?.code,
        DepartureDate: moment.parseZone(departureFlight?.departureDateTime).format(
            "YYYY-MM-DD"
        ),
        ReturnDate: moment.parseZone(returnFlight?.departureDateTime).format("YYYY-MM-DD"),
        apiDetailsUri: offer?.roomTypes[0]?.links?.apiDetails?.href,
    };

    if (searchfilters?.flightCabinClass) {
        params["flightCabinClass"] = searchfilters?.flightCabinClass;
    }

    dispatch(resetBookingState());
    dispatch(setPaginatedRoomTypes([]));
    dispatch(setPaginatedRoomFlights([]));

    let queryString = "";

    Object.keys(params)?.forEach((element, index) => {
        queryString += `${element}=${params[element]}`;
        if (Object.keys(params)?.length - 1 !== index) {
            queryString += "&";
        }
    });

    if (replace) {
        navigate(`/${page}/query?${queryString}`, {replace: true});
    } else {
        navigate(`/${page}/query?${queryString}`);
    }
};

export const handleFavoriteHotelItem = (
    loginconfig,
    dispatch,
    searchfilters,
    offer,
    appconfig,
    index = null,
    handler = null,
    flightData
) => {
    if (loginconfig?.loginUser?.guid && loginconfig?.isLogin) {
        // console.log("OFFER", offer);
        if (!offer?.favoriteGuid) {
            const infants = searchfilters.travelers.reduce(
                (n, e) => (e.infants ? n + e.infants : n),
                0
            );

            const objUrl = new URL(offer?.roomTypes[0]?.links?.webDetails?.href);
            const searchparams = new URLSearchParams(objUrl.search);
            const adultsCount = searchparams
                .get("NumAdult")
                ?.split("|")
                .reduce(
                    (previous, current) => previous + Number(current.split(":")[1]),
                    0
                );
            const childCount = searchparams
                .get("NumChild")
                ?.split("|")
                .reduce(
                    (previous, current) => previous + Number(current.split(":")[1]),
                    0
                );

            // const flightData = offer?.objHotel?.newPackagedOffer?.flightOffer;
            const departureSegmentId = flightData?.segmentIds[0];
            const departureFlightSegment = flightData?.segments?.filter(
                (f) => f.segmentId === departureSegmentId
            )[0];
            const departureAirport =
                departureFlightSegment?.legs[0]?.departureAirport?.code;

            const {checkInDate, checkOutDate} =
                getCheckInOutDatesFromFlight(flightData);

            const postObject = {
                guid: loginconfig?.loginUser?.guid,
                hotelId: offer?.id,
                country: offer?.location?.address?.country,
                hotel: offer?.name,
                location: offer?.location?.address?.city,
                starrating: offer?.starRating?.split(".")[0],
                Departure: departureAirport,
                departuredate: checkInDate,
                returndate: checkOutDate,
                price: offer?.price?.totalPrice,
                adults: adultsCount || 1,
                children: childCount || 0,
                infants: infants || 0,
                detailslink: offer?.roomTypes[0]?.links?.apiDetails?.href,
                hotelimg:
                    offer?.thumbnailUrl ||
                    "http://www.geniustravel.com/background-image-banner.jpg",
            };

            // console.log({postObject});

            AddToFavoriteList(postObject)
                .then((response) => {
                    // console.log({index});
                    if (index === null) {
                        dispatch(
                            setSelectedHotel({
                                ...offer,
                                favoriteGuid: response?.data?.favoriteGuid,
                            })
                        );
                        dispatch(
                            setHotelBooking({
                                ...offer,
                                favoriteGuid: response?.data?.favoriteGuid,
                            })
                        );
                    } else {
                        let _offers = [...appconfig.offers];
                        _offers[index] = {
                            ...offer,
                            favoriteGuid: response?.data?.favoriteGuid,
                        };
                        dispatch(setOffers(_offers));
                    }
                })
                .catch((error) => {
                    console.log({error});
                    dispatch(removeOffer(offer?.id));
                });
        } else {
            // const _index = appconfig.favPkgs?.findIndex((i) => i?.id === offer?.id);
            RemoveFromFavoriteList({
                guid: loginconfig?.loginUser?.guid,
                favoriteguid: offer?.favoriteGuid,
            }).then((response) => {
                console.log({response});
                if (handler) {
                    handler(index);
                }
                if (index === null) {
                    dispatch(setSelectedHotel({...offer, favoriteGuid: null}));
                    dispatch(setHotelBooking({...offer, favoriteGuid: null}));
                } else {
                    let _offers = [...appconfig.offers];
                    let _index = [...appconfig.offers].findIndex((f, i) => i === index);
                    if (_index !== -1) {
                        _offers[_index] = {
                            ...offer,
                        };
                        _offers[_index]["favoriteGuid"] = null;
                        dispatch(setOffers(_offers));
                    }
                }
            });
        }
    } else {
        dispatch(setModals({login: true, register: false, confirmation: false}));
    }
};

export const getFlightsParams = (
    room,
    searchfilters,
    loginconfig,
    booking,
    appConfig,
    hotelId,
    guid,
    isFav = false
) => {
    const {adults, childs, infants} = getTravelersData(searchfilters);

    const flightData = isFav
        ? booking.flight
            ? booking.flight
            : searchfilters.roomFlights[0]
        : getFlightOfferFromAppConfigOffers(appConfig, hotelId); // Selected flight from redux

    // departure ticket data
    const departureSegmentId = flightData?.segmentIds[0];
    const departureFlightSegment = flightData?.segments?.filter(
        (f) => f.segmentId === departureSegmentId
    )[0];
    const departureFlight = departureFlightSegment?.legs[0];
    // End of data

    // Arrival ticket data
    const arrivalSegmentId =
        flightData?.segmentIds[flightData?.segmentIds?.length - 1];
    const arrivalFlightSegment = flightData?.segments?.filter(
        (f) => f.segmentId === arrivalSegmentId
    )[0];
    const returnFlight = arrivalFlightSegment?.legs[0];
    // End of data

    const params = {
        Departure: departureFlight?.departureAirport?.code,
        Destination: returnFlight?.departureAirport?.code,
        DepartureDate: moment.parseZone(departureFlight?.departureDateTime).format(
            "YYYY-MM-DD"
        ),
        ReturnDate: moment.parseZone(returnFlight?.departureDateTime).format("YYYY-MM-DD"),
        Adults: adults,
        Children: childs,
        Infants: infants,
        Locale: loginconfig?.locale?.code,
        currency: loginconfig?.currency?.code,
        NumberOfStops: searchfilters?.numberOfStops,
        RoomKey: room?.roomKey,
        GUID: guid,
        FlightOfferId: booking?.flight?.offerId || null,
    };

    if (searchfilters?.flightCabinClass) {
        params["flightCabinClass"] = searchfilters?.flightCabinClass;
    }

    return params;
};

export const getTravelersData = (searchFilters) => {
    const adults = searchFilters.travelers.reduce(
        (n, e) => (e.adult ? n + e.adult : n),
        0
    );
    const infants = searchFilters.travelers.reduce(
        (n, e) => (e.infants ? n + e.infants : n),
        0
    );
    const childs = searchFilters.travelers.reduce(
        (n, e) => (e.child ? n + e.child : n),
        0
    );

    return {adults, infants, childs, total: adults + infants + childs};
};

export const getFlightsDataFromFlightOffer = (flightOffer) => {
    // departure ticket data
    const departureSegmentId = flightOffer?.segmentIds[0];
    const departureFlightSegment = flightOffer?.segments?.filter(
        (f) => f.segmentId === departureSegmentId
    )[0];
    const departureFlight = departureFlightSegment?.legs[0];
    // End of data

    // Arrival ticket data
    const arrivalSegmentId =
        flightOffer?.segmentIds[flightOffer?.segmentIds?.length - 1];
    const arrivalFlightSegment = flightOffer?.segments?.filter(
        (f) => f.segmentId === arrivalSegmentId
    )[0];
    const returnFlight = arrivalFlightSegment?.legs[0];

    return {departureFlight, returnFlight};
};

export const getHotelQuickFiltersParams = (
    searchFilters,
    loginConfig,
    appConfig,
    guid,
    page = 1
) => {
    let quickSearchParams = {
        GUID: guid,
        locale: `${loginConfig.locale.code}`,
        Page: page,
        FilterPrePost: {
            minPrice: searchFilters.defaultPrice.low,
            maxPrice: searchFilters.defaultPrice.high,
        },
        // SortOrder: searchFilters.sortingOrder,
    };

    let passengerAgeRoomItemList = [];
    let roomCounter = 1;
    searchFilters.travelers.forEach((element) => {
        for (let i = 0; i < element.infants; i++) {
            passengerAgeRoomItemList.push({
                PassengerCategory: "Infant",
                Room: roomCounter,
            });
        }
        for (let i = 0; i < element.adult; i++) {
            passengerAgeRoomItemList.push({
                PassengerCategory: "Adult",
                Room: roomCounter,
            });
        }
        for (let i = 0; i < element.child; i++) {
            passengerAgeRoomItemList.push({
                PassengerCategory: "Child",
                Room: roomCounter,
            });
        }
        roomCounter++;
    });

    if (passengerAgeRoomItemList.length > 0) {
        quickSearchParams["PassengerAgeRoomItemList"] = passengerAgeRoomItemList;
    }

    let flightSegmentList = [];
    let inbound_params = {};
    let outbound_params = {};

    const {departureFlight, returnFlight} = getFlightsDataFromFlightOffer(
        appConfig?.offers[0]?.objHotel?.newPackagedOffer?.flightOffer
    );

    inbound_params.Departure = {
        CodeList: [departureFlight?.departureAirport?.code],
        Date: moment(searchFilters.travelDate.startDate).format("YYYY-MM-DD"),
    };

    inbound_params.Arrival = {
        CodeList: [returnFlight?.departureAirport?.code],
    };

    outbound_params.Departure = {
        CodeList: [returnFlight?.departureAirport?.code],
        Date: moment(searchFilters.travelDate.endDate).format("YYYY-MM-DD"),
    };

    outbound_params.Arrival = {
        IataCodeList: [departureFlight?.departureAirport?.code],
    };

    flightSegmentList.push(inbound_params);
    flightSegmentList.push(outbound_params);

    if (flightSegmentList.length > 0) {
        quickSearchParams["FlightSegmentList"] = flightSegmentList;
    }

    // console.log({quickSearchParams});

    return quickSearchParams;
};

export const getFlightOfferFromAppConfigOffers = (appConfig, hotelId) => {
    const hotel = appConfig.offers?.find((f) => f.id === hotelId);

    if (hotel) {
        return {
            price: hotel?.objHotel?.price,
            links: hotel.roomTypes[0].links,
            surcharges: 0,
            ...hotel?.objHotel?.newPackagedOffer?.flightOffer,
        };
    }
    return null;
};

export const calculateRoomsUpSell = (room, roomTypes, dispatch) => {
    return new Promise((resolve) => {
        const selectedRoomRateDifference = Number(room?.rateDifference);
        const updatedRoomsArray = roomTypes?.map((_room) => {
            return {
                ..._room,
                rateDifference: (
                    Number(_room.rateDifference) - selectedRoomRateDifference
                ).toFixed(2),
            };
        });
        dispatch(setSelectedHotelDetail(updatedRoomsArray));
        dispatch(setHotelRoom(room));
        resolve();
    });
};

// export const getLocaleDate = (t, date, format) => {
//     const stringArray = format.split(" ");
//     if (stringArray.length === 1) {
//         return moment(date).format(format);
//     } else {
//         return (
//             t(`Months.${moment(date).format(format.split(" ")[0])}`) +
//             " " +
//             moment(date).format(format.split(" ")[1])
//         );
//     }
// };

export const getSearchOnlyListingParams = ({
                                               guid = "",
                                               page = 1,
                                               searchfilters,
                                               loginconfig,
                                               selectedPackage = null,
                                               initialSearch = false,
                                               bigSearch = false,
                                               SearchType,
                                           }) => {
    let PassengerAgeRoomItemList = [];
    let roomCounter = 1;
    let FlightSegmentList = [];
    let inbound_params = {};
    let outbound_params = {};

    let searchParams = {
        GUID: guid,
        Locale: `${loginconfig?.locale?.code}`,
        Page: page,
        FilterPrePost: {
            minPrice: searchfilters?.defaultPrice?.low,
            maxPrice: searchfilters?.defaultPrice?.high,
            // minStarRating: searchfilters.starRating.min,
            // maxStarRating: searchfilters.starRating.max,
            // flightCabinClass: searchfilters?.flightCabinClass,
        },
        SortOrder: 'Price',
        currency: loginconfig?.currency?.code,
        SearchType,
    }

    if (!initialSearch) {
        searchfilters?.travelers?.forEach((element) => {
            for (let i = 0; i < element.infants; i++) {
                PassengerAgeRoomItemList.push({
                    PassengerCategory: "Infant",
                    Room: roomCounter,
                });
            }
            for (let i = 0; i < element.adult; i++) {
                PassengerAgeRoomItemList.push({
                    PassengerCategory: "Adult",
                    Room: roomCounter,
                });
            }
            for (let i = 0; i < element.child; i++) {
                PassengerAgeRoomItemList.push({
                    PassengerCategory: "Child",
                    Room: roomCounter,
                });
            }
            roomCounter++;
        });

        if (searchfilters?.departureFrom?.length) {
            inbound_params.Departure = {
                CodeList: searchfilters.departureFrom?.map((m) => m?.code),
                Date: moment(searchfilters.travelDate?.startDate)?.format("YYYY-MM-DD"),
            };

            if (
                searchfilters.travelTime &&
                searchfilters.travelTime?.departureTime &&
                searchfilters.travelTime?.departureTime !== "Anytime"
            ) {
                inbound_params.Departure["StartTime"] =
                    searchfilters.travelTime?.departureTime?.split("-")[0];
                inbound_params.Departure["EndTime"] =
                    searchfilters.travelTime?.departureTime?.split("-")[1];
            }

            inbound_params.Arrival = {
                CodeList: selectedPackage
                    ? selectedPackage?.destination?.split(",")
                    : [
                        searchfilters?.selectedDesireCountry?.id === "00001" &&
                        searchfilters?.selectedDesireCountry?.airportCode === "001"
                            ? `[ALL][000001][Anywhere]`
                            : `[${searchfilters.selectedDesireCountry?.airportCode}][${searchfilters.selectedDesireCountry?.id}][${searchfilters.selectedDesireCountry?.keyword}]`,
                    ],
            };

            inbound_params["NumberOfStops"] =
                searchfilters.appFilters.find((f) => f.key === "steps")?.id ||
                searchfilters.numberOfStops;

            outbound_params.Departure = {
                CodeList: selectedPackage
                    ? selectedPackage?.destination?.split(",")
                    : [
                        searchfilters?.selectedDesireCountry?.id === "00001" &&
                        searchfilters?.selectedDesireCountry?.airportCode === "001"
                            ? `[ALL][000001][Anywhere]`
                            : `[${searchfilters.selectedDesireCountry?.airportCode}][${searchfilters.selectedDesireCountry?.id}][${searchfilters.selectedDesireCountry?.keyword}]`,
                    ],
                Date: moment(searchfilters.travelDate.endDate).format("YYYY-MM-DD"),
            };

            if (
                searchfilters.travelTime &&
                searchfilters.travelTime?.arrivalTime &&
                searchfilters.travelTime?.arrivalTime !== "Anytime"
            ) {
                outbound_params.Departure["StartTime"] =
                    searchfilters.travelTime?.arrivalTime?.split("-")[0];
                outbound_params.Departure["EndTime"] =
                    searchfilters.travelTime?.arrivalTime?.split("-")[1];
            }

            outbound_params.Arrival = {
                IataCodeList: searchfilters.departureFrom?.map((m) => m.code),
            };

            outbound_params["NumberOfStops"] =
                searchfilters.appFilters.find((f) => f.key === "steps")?.id ||
                searchfilters.numberOfStops;

            FlightSegmentList.push(inbound_params);
            FlightSegmentList.push(outbound_params);
        }

        if (FlightSegmentList.length > 0) {
            searchParams["FlightSegmentList"] = FlightSegmentList;
        }

        if (PassengerAgeRoomItemList.length > 0) {
            searchParams["PassengerAgeRoomItemList"] = PassengerAgeRoomItemList;
        }
    } else if (!location.pathname.includes("/search/")) {
        searchParams.FilterPrePost.minStarRating = searchfilters.starRating.min;
        searchParams.FilterPrePost.maxStarRating = searchfilters.starRating.max;
    }

    if (bigSearch) {
        searchParams["FilterPrePost"]["minStarRating"] =
            searchfilters.appFilters.find((f) => f.key === "guest_rating")?.id ||
            searchfilters.starRating.min;
        searchParams["FilterPrePost"]["maxStarRating"] =
            searchfilters.starRating.max;
        searchParams["FilterPrePost"]["flightCabinClass"] =
            searchfilters.appFilters.find((f) => f.key === "airline_class")?.value ||
            searchfilters?.flightCabinClass;

        const hotelAmenitiesArray = searchfilters.appFilters.filter(
            (f) => f.key === "popular_filters"
        );
        if (hotelAmenitiesArray?.length) {
            let _PackageFilter = [];
            hotelAmenitiesArray.forEach((f) => {
                _PackageFilter.push({name: f?.value});
            });
            searchParams["FilterPrePost"]["PackageFilter"] = _PackageFilter;
        }
    }

    return searchParams;
};

export const getThreeFilterToShow = (room, t, flight) => {
    let filters = [];

    try {
        if (room !== null) {
            // Created start and end date
            // const checkInDate = room?.ratePlans[0]?.stayDates?.checkInDate;
            // const checkOutDate = room?.ratePlans[0]?.stayDates?.checkOutDate;
            const {checkInDate, checkOutDate} = getCheckInOutDatesFromFlight(flight);
            const firstChip = {
                id: 1,
                title: `${moment.parseZone(checkInDate).format("MMM DD")}-${moment.parseZone(
                    checkOutDate
                ).format("DD")}`,
            };
            filters.push(firstChip);

            // Creating room and person count from URL
            const objUrl = new URL(room?.links?.webDetails?.href);
            if (objUrl) {
                const searchparams = new URLSearchParams(objUrl?.search);
                const roomsCount = searchparams.get("NumRoom");
                const adultsCount = searchparams
                    .get("NumAdult")
                    ?.split("|")
                    .reduce(
                        (previous, current) => previous + Number(current.split(":")[1]),
                        0
                    );
                const childCount = searchparams
                    .get("NumChild")
                    ?.split("|")
                    .reduce(
                        (previous, current) => previous + Number(current.split(":")[1]),
                        0
                    );
                let total = 0;
                if (adultsCount) {
                    total = Number(total) + Number(adultsCount);
                }

                if (childCount) {
                    total = Number(total) + Number(childCount);
                }

                const secondChip = {
                    id: 2,
                    title: `${total} pers / ${roomsCount} rm`,
                };
                filters.push(secondChip);
            }

            // Creating days of staying
            let nights = nightDifferenceCountForFlightsDate(checkInDate, checkOutDate);
            nights = Number(nights);
            const thirdChip = {
                id: 3,
                title: `${nights} ${
                    nights > 1 ? t("DetailCard.nightsRange") : t("DetailCard.nightRange")
                }`,
            };
            filters.push(thirdChip);
        }
    } catch (e) {
        console.log({e});
    }

    return filters;
};

export const updateFlightPrice = (
    totalTravelers,
    priceToAdd,
    previousSelectedFlight,
    dispatch,
    newFlight = null,
    isItChooseRoom = false,
) => {
    let obj = {...previousSelectedFlight?.price};
    obj.packageTotalPrice = {
        value: (
            Number(obj?.packageTotalPrice?.value) +
            priceToAdd * totalTravelers
        ).toFixed(2),
        currency: obj?.packageTotalPrice?.currency,
    };
    obj.standAloneTotalPrice = {
        value: (
            Number(obj?.standAloneTotalPrice?.value) +
            priceToAdd * totalTravelers
        ).toFixed(2),
        currency: obj?.standAloneTotalPrice?.currency,
    };
    obj.averageCostPerPerson = {
        value: (Number(obj?.averageCostPerPerson?.value) + priceToAdd).toFixed(2),
        currency: obj?.averageCostPerPerson?.currency,
    };

    if (isItChooseRoom) {
        return {...previousSelectedFlight, price: obj};
    }

    if (newFlight === null) {
        dispatch(
            setBothFlight({
                newFlight: {...previousSelectedFlight, price: obj},
                oldFlight: previousSelectedFlight,
            }),
        );
    } else if (newFlight) {
        dispatch(
            setBothFlight({
                newFlight: {...newFlight, price: obj},
                oldFlight: previousSelectedFlight,
            }),
        );
    }
};

export const getTravelerCountFromRoomUrl = url => {
    let total = 0;
    if (url) {
        const objUrl = new URL(url);
        if (objUrl) {
            const searchParams = new URLSearchParams(objUrl.search);
            const adultsCount = searchParams
                .get('NumAdult')
                ?.split('|')
                .reduce(
                    (previous, current) => previous + Number(current.split(':')[1]),
                    0,
                );
            const childCount = searchParams
                .get('NumChild')
                ?.split('|')
                .reduce(
                    (previous, current) => previous + Number(current.split(':')[1]),
                    0,
                );
            if (adultsCount) {
                total = Number(total) + Number(adultsCount);
            }
            if (childCount) {
                total = Number(total) + Number(childCount);
            }
        }
    }
    return total;
};

export const moveNumberToEnd = str => {
    const regex = /(\d+)-?(\s|$)/; // Match numbers followed by "-" or whitespace
    const match = str.match(regex);

    if (match) {
        const number = match[1];
        const prefix = str.replace(regex, ''); // Remove the number and "-" or whitespace
        const finalString = number + ' ' + prefix.trim(); // Add a space after the number and trim any extra spaces
        return finalString.endsWith('-') ? finalString.slice(0, -1) : finalString; // Remove "-" at the end if present
    } else {
        return str; // Return the string unchanged if no match is found
    }
};

export const getCheckInOutDatesFromFlight = flight => {
    const departureSegmentId = flight?.segmentIds[0];
    const departureFlightSegment = flight?.segments?.filter(
        f => f.segmentId === departureSegmentId,
    )[0];
    const departureStart = departureFlightSegment?.legs[0];


    const arrivalSegmentId = flight?.segmentIds[flight?.segmentIds?.length - 1];
    const arrivalFlightSegment = flight?.segments?.filter(
        f => f.segmentId === arrivalSegmentId,
    )[0];
    const arrivalStart = arrivalFlightSegment?.legs[0];


    const checkInDate = departureStart?.departureDateTime;
    const checkOutDate = arrivalStart?.departureDateTime;

    return {
        checkInDate,
        checkOutDate,
    };
};
