import "./styles.scss";
import React, {useEffect, useState} from "react";
import Topbar from "../../components/sections/DetailsTopBar/DetailsTopBar";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import MapCardSlider from "../../components/sections/MapCardSlider/MapCardSlider";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {getAppConfig} from "../../redux/slices/appconfigSlice";
import LocationGray from '../../assets/images/locationGray.svg';
import LocationGreen from '../../assets/images/LocationGreen.svg';
import AppConfig from "../../helpers/config";
import {useNavigate} from "react-router-dom";

const MapPage = () => {
    const {hotelId} = useParams(); // Get the numeric ID from the URL
    const navigate = useNavigate();
    const appConfig = useSelector(getAppConfig);

    const [selectedHotel, setSelectedHotel] = useState(null);
    const [hotelLocations, setLocations] = useState([]);

    const defaultProps = {
        center: {lat: 10.99835602, lng: 77.01502627}, // Default center (fallback)
        zoom: 14,
    };

    useEffect(() => {
        window.scroll(0, 0);

        const selected = appConfig.offers?.find(f => f.id === hotelId);
        const locations = appConfig.offers?.map(f => {
            const geoLocation = f?.location?.geoLocation;
            return {
                id: f.id,
                lat: geoLocation?.latitude,
                lng: geoLocation?.longitude,
                name: f?.name,
                selected: f.id === hotelId,
            };
        });

        setSelectedHotel(selected);
        setLocations(locations);

    }, [hotelId]);

      const changeHotelSelected = (_id) => {
          const selected = appConfig.offers?.find(f => f.id === _id);
          setSelectedHotel(selected);
      }

    return (
        <div className="main-wrapper flight-page map-page">
            <main className="body-wrapper">
                <div style={{height: "100vh", width: "100%", display: "flex"}}>
                    <div className={'hotels-container map-desktop'}>
                        <MapCardSlider isDesktop={true}/>
                    </div>

                    <div className="map-wrapper">
                        <div className="top-bar-container">
                            <Topbar isMap={true}/>
                        </div>
                        <GoogleMapReact
                            bootstrapURLKeys={{key: AppConfig.googleMapAPIKey}}
                            center={
                                selectedHotel?.location?.geoLocation
                                    ? {
                                        lat: Number(selectedHotel.location.geoLocation.latitude),
                                        lng: Number(selectedHotel.location.geoLocation.longitude)
                                    }
                                    : defaultProps.center
                            }
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                        >
                            {hotelLocations?.map((location, index) => (
                                <Marker
                                    key={index}
                                    id={location.id}
                                    lat={Number(location.lat)}
                                    lng={Number(location.lng)}
                                    text={location.name}
                                    selected={location.selected}
                                    onClick={() => {
                                        navigate(`/hotel/${location?.id}/map`, {replace: true});
                                        changeHotelSelected(location?.id);
                                    }}
                                />
                            ))}
                        </GoogleMapReact>
                        <div className="mobile-sec-map mapcards-slider">
                            <MapCardSlider isDesktop={false}/>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

const Marker = (props) => {
    return (
        <div className={'marker-container'} style={{cursor: 'pointer'}} onClick={props.onClick}>
            {props.selected && <div>{props?.text}</div>}
            {props.selected ? (
                <img src={LocationGreen} alt="marker-green-icon"/>
            ) : (
                <img src={LocationGray} alt="marker-gray-icon"/>
            )}
        </div>
    );
};

Marker.propTypes = {
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    text: PropTypes.string,
    selected: PropTypes.bool,
    onClick: PropTypes.func
};

export default MapPage;
