import React, { useState } from "react";
import ShareIcon from "../../../assets/images/icons/icon-share.svg";
import HeartIcon from "../../../assets/images/icons/icon-heart.svg";
import HeartFilledIcon from "../../../assets/images/icons/icon-heart-filled.svg";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAppConfig, setActivityIndicator } from "../../../redux/slices/appconfigSlice";
import { getLoginConfig } from "../../../redux/slices/loginconfigSlice";
import { getSearchFilters } from "../../../redux/slices/searchFilterSlice";
import { getFlightsParams, handleFavoriteHotelItem } from "../../../helpers/common";
import { useTranslation } from "react-i18next";
import { getBooking } from "../../../redux/slices/bookingSlice";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import branch from "branch-sdk";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import PropTypes from "prop-types";
import Lottie from "lottie-react";
import MapJson from '../../../assets/images/lottieViews/map.json';
import AppConfig from "../../../helpers/config";
import Expedia from "../../../assets/images/icons/expedia.svg";
import ExpediaDark from "../../../assets/images/icons/expedia-logo-dark.svg";
import { GetFlightDetailsService } from "../../../services/HotelDetailServices";
import BackArrow from "../../../assets/images/arrow.svg";
import ShareBranchLink from "./ShareBranchLink";

const TopBar = ({isMap = false, detailRef = null}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const booking = useSelector(getBooking);
    const { room } = booking;
    const { selectedHotel } = useSelector(getSearchFilters);
    const appconfig = useSelector(getAppConfig);
    const loginconfig = useSelector(getLoginConfig);
    const searchfilters = useSelector(getSearchFilters);
    const [shortLink, setShortLink] = useState("");

    const createLink = () => {

        const getURL = AppConfig.baseApiURL.includes('staging')
            ? 'https://staging.genius.travel/'
            : 'https://genius.travel/';


        const linkData = {
            campaign: 'genius/package',
            data: {
                payload: JSON.stringify(selectedHotel),
                '$ios_url': getURL,
                '$ios_url_xx': getURL,
                '$ipad_url': getURL,

                '$android_url': getURL,
                '$android_url_xx': getURL,
                '$android_deeplink_path': 'geniustravel://',

                '$og_title': selectedHotel?.name,
                '$og_description': `★ ${selectedHotel?.starRating} • ${selectedHotel?.address}`,
                '$og_image_url': selectedHotel?.thumbnailUrl
            }
        };

        branch.link(linkData, function (err, link) {
            // console.log(link);
            setShortLink(link);
        });
    }

    const handleOnBooking = () => {
        const params = getFlightsParams(
            room,
            searchfilters,
            loginconfig,
            booking,
            appconfig,
            searchfilters?.selectedHotel?.id,
            searchfilters?.selectedHotel?.guidForBooking
        );
        const BaseUrl = window.location.origin;
        const newTab = window.open(`${BaseUrl}/fetching`, '_blank');

        try {
            params.GetFinalExpediaUrl = true;
            params.transaction_id = appconfig?.transaction_id;

            // console.log(JSON.stringify(params), "params for getting more flights");

            dispatch(setActivityIndicator(true));
            GetFlightDetailsService(params)
                .then((_flightDetails) => {
                    dispatch(setActivityIndicator(false));

                    // console.log({_flightDetails});

                    if (
                        _flightDetails?.warnings?.length &&
                        _flightDetails?.warnings[0]?.code === "400.19"
                    ) {
                        toast.info(_flightDetails?.warnings[0]?.description);
                        return;
                    }

                    const externalUrl = _flightDetails?.offers[0]?.links?.webDetails?.href;
                    if (newTab) {
                        newTab.location.href = externalUrl;
                    }
                })
                .catch(() => {
                    dispatch(setActivityIndicator(false));
                    if (newTab) {
                        newTab.close();
                    }
                });
        } catch (e) {
            if (newTab) {
                newTab.close();
            }
        }
    };

    const splitString = (input) => {
        const regex = /\b(or|of)\b/i; // Matches "or" or "of" as whole words, case-insensitively
        const match = input.match(regex);
        if (match) {
            const index = match.index;
            // const word = match[0];
            // Split the string into two parts: before and after the matched word (inclusive)
            const before = input.slice(0, index).trim();
            const after = input.slice(index).trim();
            return [before, after]; // Keep the word and the text after it together
        }
        // Return the input as a single element if no match
        return [input];
    }

    function handleClick(index) {
        if (index === 0) {
            handleOnBooking();
        } else {
            detailRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }

    return (
        <div id="topBar" className="topbar-wrapper">
            <div className="container">
                <div className={isMap ? '' : 'content-holder'}>
                    <div className="left-action" onClick={() => navigate(-1)}>
                        {!isMap && <img className="" src={BackArrow} alt="back-arrow-icon" />}
                        {isMap && <a>{t("Buttons.Back")}</a>}
                    </div>

                    {!isMap && <div className={'expedia-section'}>
                        <div>
                            <p onClick={() => handleClick(0)}>
                                {splitString(t('TopBar.Top_Bar_Direct_Expedia'))[0]}
                            </p>
                            <p onClick={() => handleClick(1)}>
                                {splitString(t('TopBar.Top_Bar_Direct_Expedia'))[1]}
                            </p>
                        </div>
                        <img
                            src={loginconfig.darkMode ? Expedia : ExpediaDark}
                            alt={"expedia"}
                        />
                    </div>}

                    {!isMap && <div className="right-action">
                        <ul>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    className="show-map-link"
                                    // href={`https://maps.google.com/?q=${selectedHotel?.location?.geoLocation?.latitude},${selectedHotel?.location?.geoLocation?.longitude}`}
                                    // target="_blank"
                                    onClick={() => navigate(`/hotel/${selectedHotel?.id}/map`)}
                                >
                                    <Lottie animationData={MapJson} loop={true} className='map_animation' />
                                    <span>{t("TopBar.ShowOn")}{" "}{t("TopBar.Map")}</span>
                                </a>
                            </li>
                            <li>
                                <Dropdown className="share-button" onToggle={createLink}>
                                    <Dropdown.Toggle>
                                        <img src={ShareIcon} alt={"share-icon"} />
                                        {t("Buttons.Share")}
                                    </Dropdown.Toggle>
                                    {shortLink &&
                                        <Dropdown.Menu>
                                            <h6>{t("CopyToClipBoard")}</h6>
                                            <div className="d-flex">
                                                <input
                                                    readOnly={true}
                                                    type="text"
                                                    id="urlField"
                                                    value={shortLink}
                                                ></input>
                                                <CopyToClipboard
                                                    text={shortLink}
                                                    onCopy={() => {
                                                        toast.success(`${t("TopBar.LinkCopied")}`);
                                                    }}>
                                                    <button id="copyButton">
                                                        <FontAwesomeIcon icon={faCopy} />
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                            <div>
                                                <ShareBranchLink shortLink={shortLink}/>
                                            </div>
                                        </Dropdown.Menu>
                                    }
                                </Dropdown>
                            </li>
                            <li>
                                <a
                                    onClick={() =>
                                        handleFavoriteHotelItem(
                                            loginconfig,
                                            dispatch,
                                            searchfilters,
                                            searchfilters?.selectedHotel,
                                            appconfig,
                                            null,
                                            null,
                                            booking?.flight
                                        )
                                    }
                                >
                                    <img
                                        src={
                                            selectedHotel?.favoriteGuid ? HeartFilledIcon : HeartIcon
                                        }
                                        alt={"heart-icon"}
                                    />
                                    {selectedHotel?.favoriteGuid
                                        ? t("Buttons.Saved")
                                        : t("Buttons.Save")}
                                </a>
                            </li>
                        </ul>
                    </div>}
                </div>
            </div>
        </div>
    );
};

TopBar.propTypes = {
    isMap: PropTypes.bool,
    detailRef: PropTypes.any
}

export default TopBar;
