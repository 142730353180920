import React, {useState} from "react";
import Checkbox from "../../CustomCheckbox/CustomCheckbox";
import Accordion from "react-bootstrap/Accordion";
import "./style.scss";
import {useSelector} from "react-redux";
import {getSearchFilters} from "../../../redux/slices/searchFilterSlice";
import PropTypes from "prop-types";

const Collapsible = ({
                         _activeKey,
                         title,
                         items,
                         handleClickEvent,
                         multiple = false,
                     }) => {
    const searchfilters = useSelector(getSearchFilters);
    const [activeKey, setActiveKey] = useState('');

    const toggleAccordion = (eventKey) => {
        setActiveKey(activeKey === eventKey ? null : eventKey);
    };

    return (
        <Accordion
            className="collapsible-filter"
            activeKey={activeKey}
            onSelect={toggleAccordion}
        >
            <Accordion.Item eventKey={_activeKey}>
                <Accordion.Header>{title}</Accordion.Header>
                <Accordion.Body>
                    <div className="checklist">
                        {items?.map((item, index) => {
                            return (
                                <Checkbox
                                    key={index}
                                    id={`checkbox-${item.id}-${item.text}-${item?.key}`}
                                    text={item.text}
                                    icon={item.icon ? "" : ""}
                                    onChange={(e) => handleClickEvent(e, item, multiple)}
                                    checked={searchfilters.appFilters?.some(
                                        (f) =>
                                            f._id === `checkbox-${item.id}-${item.text}-${item?.key}`
                                    )}
                                ></Checkbox>
                            );
                        })}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

Collapsible.propTypes = {
    _activeKey: PropTypes.string,
    title: PropTypes.string,
    items: PropTypes.array,
    handleClickEvent: PropTypes.func,
    multiple: PropTypes.bool,
}

export default Collapsible;
