import React, { useState } from "react";
import BlogImage from "../../assets/images/blog-image.png";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/pro-light-svg-icons";

const BlogCardList = () => {
  const { t } = useTranslation();
  const [more, setMore] = useState(false);

  return (
    <div className="blog-card-list">
      <div className="blog-card-wrapper">
        <div className="blog-image">
          <img src={BlogImage} />
        </div>
        <div className="blog-content">
          <h2 className="heading heading-2">
            Genius Travel
          </h2>
          {!more ? (
            <p>{t("BlogSection").split("...")[0]}</p>
          ) : (
            <p>{t("BlogSection")}</p>
          )}
          {!more && (
            <a className="read-more" onClick={() => setMore(true)}>
              {t("Buttons.ReadMore")}
              <FontAwesomeIcon icon={faAngleRight} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogCardList;
