import "./style.scss";
import moment from "moment";
import {format} from "date-fns";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import RangeBar from "../../slider/RangeBar";
import {useNavigate} from "react-router-dom";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import React, {useState, useEffect, useRef} from "react";
import {DateRangePicker} from "react-date-range";
import Dropdown from "react-bootstrap/Dropdown";
import useWindowDimensions from "../../../utils/getWindowDimensions";
import {
    getSearchFilters,
    setDepartureFrom,
    setDesireCountry,
    setListingGuid,
    setPriceRangeValue,
    setSelectedPackage as _setSelectedPackage,
    setTravelDate,
    setStarRating,
} from "../../../redux/slices/searchFilterSlice";
import {GetBestTravelDates} from "../../../services/AppService";
import Travelers from "./Travelers";
import {useTranslation} from "react-i18next";
import {
    getAppConfig,
    getHotels,
    setActivityIndicator,
    setPackageTypesState,
    setPagination,
} from "../../../redux/slices/appconfigSlice";
import {
    getRandomPackageFromArray,
    getSearchOnlyListingParams,
    getTravelersData,
} from "../../../helpers/common";
import {getLoginConfig} from "../../../redux/slices/loginconfigSlice";
import {GetPackageTypesService} from "../../../services/HomePackageTypesSevice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {nl, enUS} from "date-fns/locale";

import {faCalendar} from "@fortawesome/pro-light-svg-icons";
import {faLocationDot, faUser} from "@fortawesome/pro-solid-svg-icons";
import DesireCountryModal from "../../Modals/DesireCountryModal";
import DepartureFromModal from "../../Modals/DepartureFromModal";
import {toast} from "react-toastify";
import TooltipSlider from "../../TooltipSlider";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import Finger from "../../../assets/images/lottieViews/finger.json";
import SearchGrow from "../../../assets/images/lottieViews/search-grow.json";
import PropTypes from "prop-types";
import Lottie from "lottie-react";
import AppConfig from "../../../helpers/config";

const QuickFilter = ({
                         searchPageComponent = null,
                         dateShouldReset = false,
                         setDateShouldReset = null,
                     }) => {
    const {t} = useTranslation();
    const calendarRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const {width} = useWindowDimensions();
    const searchfilters = useSelector(getSearchFilters);
    const loginConfig = useSelector(getLoginConfig);
    const appConfig = useSelector(getAppConfig);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showTraveler, setShowTraveler] = useState(false);
    const [justArrived, setJustArrived] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [isReset, setIsReset] = useState(
        !searchfilters.travelDate.startDate && !searchfilters.travelDate.endDate
    );
    const [endDate, setEndDate] = useState("");
    const [bestDates, setBestDates] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(
        searchfilters.selectedPackage
    );
    const [dateRangeState, setDateRangeState] = useState([
        {
            key: "selection",
            startDate: searchfilters.travelDate.startDate
                ? new Date(searchfilters.travelDate.startDate)
                : new Date(),
            endDate: searchfilters.travelDate.endDate
                ? new Date(searchfilters.travelDate.endDate)
                : new Date(),
        },
    ]);
    const [modals, setModals] = useState({
        destination: false,
        departure: false,
    });

    // Home page Hooks  **TESTED**
    useEffect(() => {
        if (location.pathname === "/" && !showTraveler) {
            GetPackageTypes();
        }
    }, [showTraveler, loginConfig.locale, loginConfig.currency]);

    // **Tested**
    useEffect(() => {
        if (!justArrived) {
            const params = getSearchOnlyListingParams({
                guid: appConfig?.correlationId,
                page: 1,
                searchfilters,
                loginconfig: loginConfig,
                initialSearch: true,
                SearchType: 'Tile'
            });

            dispatch(getHotels(params));

            if (location.pathname.includes("/search/")) {
                navigate(
                    "/search/" +
                    appConfig.correlationId +
                    "/query?page=1&locale=" +
                    loginConfig.locale.code +
                    "&currency=" +
                    loginConfig?.currency?.code,
                    {replace: true}
                );
            }

        } else {
            setJustArrived(false); // Turning off first time arrival
        }
    }, [
        searchfilters.defaultPrice,
        searchfilters.starRating
    ]);

    // If there is home package selected then dates will be reset
    useEffect(() => {
        if (dateShouldReset) {
            resetDateRange();
            if (setDateShouldReset) {
                setDateShouldReset(false);
            }
        }
    }, [dateShouldReset]);

    // This will only call for home screen.
    const GetPackageTypes = () => {
        GetPackageTypesService(searchfilters, loginConfig)
            .then((_packageTypes) => {
                const HomeGUID = _packageTypes.filter((p) => p.type === "Home")[0].guid; //  Getting home type package from packages listing
                dispatch(
                    setPackageTypesState(_packageTypes.filter((p) => p.type !== "Home"))
                );
                const item = getRandomPackageFromArray(_packageTypes);
                if (HomeGUID) {
                    dispatch(setListingGuid(HomeGUID));
                    dispatch(_setSelectedPackage(null));
                    resetDateRange();
                } else {
                    // If not found then selection random selected package
                    dispatch(setListingGuid(item.guid));
                    dispatch(_setSelectedPackage(item));
                }

                const params = getSearchOnlyListingParams({
                    guid: HomeGUID ? HomeGUID : item.guid,
                    page: 1,
                    searchfilters,
                    loginconfig: loginConfig,
                    initialSearch: true,
                    SearchType: HomeGUID ? 'Home' : 'Tile'
                });

                dispatch(getHotels(params));
            })
            .catch(() => {
                dispatch(setActivityIndicator(false));
            });
    };

    // End of home page hooks

    // This will null the selected package if user set his own location
    useEffect(() => {
        if (searchfilters.selectedDesireCountry !== null && !justArrived) {
            setSelectedPackage(null);
        }
    }, [searchfilters.selectedDesireCountry]);

    // This will set desired null if user select it form drop down.
    useEffect(() => {
        if (selectedPackage !== null && !justArrived) {
            dispatch(setDesireCountry(null));
        }
    }, [selectedPackage]);

    // Mobile responsiveness
    useEffect(() => {
        width < 767 ? setIsMobile(true) : setIsMobile(false);
    }, [width]);

    // Calendar Hooks
    useEffect(() => {
        if (showCalendar) {
            getBestDatesFromServer(calendarRef?.current?.props?.ranges[0]?.endDate);
        }
    }, [showCalendar, endDate]);

    useEffect(() => {
        if (dateRangeState.length) {
            setEndDate(dateRangeState[0].endDate);
            if (
                dateRangeState[0]?.startDate?.getTime() !==
                dateRangeState[0]?.endDate?.getTime()
            ) {
                setIsReset(false);
            }
        }
    }, [dateRangeState]);

    // This will get best dates from server.
    const getBestDatesFromServer = (date) => {
        const month = new Date(date).getMonth();
        const persona =
            (appConfig.packageTypes.length && appConfig.packageTypes[0].personas) ||
            "Couples";

        try {
            Promise.all([
                GetBestTravelDates({month: month + 1, persona}),
                GetBestTravelDates({month: month + 2, persona}),
            ])
                .then((stack) => {
                    let _bestDates = [];
                    stack.forEach((response, index) => {
                        if (response?.bestTravelDates?.length) {
                            let dates = response?.bestTravelDates.map((bestDate) => {
                                const year = new Date(
                                    searchfilters.travelDate.endDate
                                ).getFullYear();
                                return {
                                    day: bestDate.dayOfMonth,
                                    date: new Date(
                                        moment(
                                            `${year}-${month + index + 1}-${bestDate.dayOfMonth}`
                                        ).format("YYYY-MM-DD")
                                    ),
                                };
                            });
                            _bestDates = [..._bestDates, ...dates];
                        }
                    });
                    // console.log({_bestDates});
                    setBestDates(_bestDates);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (e) {
            console.log({e});
        }
    };

    const dateCheckIfLiesUnder = (from, to, check) => {
        var fDate, lDate, cDate;
        fDate = Date.parse(from);
        lDate = Date.parse(to);
        cDate = Date.parse(check);
        return cDate <= lDate && cDate >= fDate;
    };

    const handleRangeChange = (item) => {
        const cSDate = item?.selection?.startDate;
        const pSSDate = dateRangeState[0]?.startDate;
        const pSEDate = dateRangeState[0]?.endDate;

        const currentDuration = moment.duration(
            moment(pSEDate).diff(moment(pSSDate))
        );
        const startDateDuration = moment.duration(
            moment(cSDate).diff(moment(pSSDate))
        );

        const endDateDuration = moment.duration(
            moment(cSDate).diff(moment(pSEDate))
        );

        const cDays = currentDuration.asDays();
        const sDays = startDateDuration.asDays();
        const eDays = endDateDuration.asDays();

        // Selected date is larger than current range. Right side click
        if (sDays >= 28) {
            setDateRangeState([
                {
                    key: "selection",
                    startDate: cSDate,
                    endDate: new Date(moment(cSDate).add(1, "days")),
                },
            ]);
        } else if (sDays < 28 && sDays > eDays) {
            if (dateCheckIfLiesUnder(pSSDate, pSEDate, cSDate)) {
                if (sDays <= 0 || cDays / 2 - sDays >= 0) {
                    setDateRangeState([
                        {
                            key: "selection",
                            startDate: cSDate,
                            endDate: pSEDate,
                        },
                    ]);
                } else if (eDays >= 0 || cDays / 2 + eDays >= 0) {
                    setDateRangeState([
                        {
                            key: "selection",
                            startDate: pSSDate,
                            endDate: cSDate,
                        },
                    ]);
                }
                return;
            }

            setDateRangeState([
                {
                    key: "selection",
                    startDate: pSSDate,
                    endDate: cSDate,
                },
            ]);
        }

        // Selected date is less than current range. left side click
        if (sDays < 0 && eDays > -28) {
            setDateRangeState([
                {
                    key: "selection",
                    startDate: cSDate,
                    endDate: pSEDate,
                },
            ]);
        } else if (sDays < 0 && eDays < -28) {
            setDateRangeState([
                {
                    key: "selection",
                    startDate: cSDate,
                    endDate: new Date(moment(cSDate).add(1, "days")),
                },
            ]);
        }

        if (
            dateRangeState[0]?.startDate?.getTime() ===
            dateRangeState[0]?.endDate?.getTime() &&
            isReset
        ) {
            setDateRangeState([
                {
                    key: "selection",
                    startDate: cSDate,
                    endDate: new Date(moment(cSDate).add(1, "days")),
                },
            ]);
        }
    };

    const customDayContent = (day) => {
        let extraDot = null;
        const getDate = (date) => moment(date).format("YYYY-MM-DD").toString();
        if (
            bestDates.filter((bD) => getDate(day) === getDate(bD.date)).length > 0
        ) {
            extraDot = <div className="dateDot"/>;
        }
        return (
            <div>
                {extraDot}
                <span>{format(day, "d")}</span>
            </div>
        );
    };

    // End of calendar hooks.

    const routeToSearchScreen = (event) => {
        event.preventDefault();
        if (location.pathname.includes("/search/"))
            return handleSearchOfCircleButton(); // If router is in search then it will call the search from scratch
        const getGuid = () =>
            searchfilters.selectedPackage === null
                ? appConfig?.offers[0]?.guidForBooking
                : searchfilters.selectedPackage.guid;
        navigate(
            "/search/" +
            getGuid() +
            "/query?page=1" +
            "&locale=" +
            loginConfig.locale.code +
            "&currency=" +
            loginConfig?.currency?.code
        );
    };

    const handlePriceChange = ({low, high}) => {
        dispatch(setPriceRangeValue({low, high}));
    };

    const handleSearchOfCircleButton = () => {
        if (!searchfilters.selectedDesireCountry) {
            return toast.info(t("PleaseChooseDestination"));
        }

        if (!searchfilters.departureFrom?.length) {
            return toast.info(t("PleaseChooseDeparture"));
        }

        if (
            !searchfilters.travelDate.startDate ||
            !searchfilters.travelDate.endDate
        ) {
            return toast.info(t("PleaseSelectDateRange"));
        }

        dispatch(setPagination(null));

        const params = getSearchOnlyListingParams({
            guid: "",
            page: 1,
            searchfilters,
            loginconfig: loginConfig,
            SearchType: 'Free'
        });

        // console.log({params});

        dispatch(getHotels(params));
    };

    const resetDateRange = () => {
        const focusedDate = dateRangeState[0].startDate;

        const firstDate = new Date(
            focusedDate.getFullYear(),
            focusedDate.getMonth(),
            1
        );

        // console.log({firstDate});

        const pasteDate =
            focusedDate.getMonth() === new Date().getMonth() ? new Date() : firstDate;

        setDateRangeState([
            {
                startDate: pasteDate,
                endDate: pasteDate,
                key: "selection",
            },
        ]);

        setIsReset(true);
    };

    const Calender = () =>
        showCalendar && (
            <div className="calendar-wrapper">
                <DateRangePicker
                    ref={calendarRef}
                    onChange={handleRangeChange}
                    minDate={new Date()}
                    maxDate={new Date(moment().add(332, "days"))}
                    dayContentRenderer={customDayContent}
                    months={2}
                    ranges={dateRangeState}
                    dragSelectionEnabled={false}
                    showMonthAndYearPickers={false}
                    showPreview={false}
                    showDateDisplay={false}
                    weekStartsOn={1}
                    direction={isMobile ? "vertical" : "horizontal"}
                    locale={loginConfig.locale.code === "nl_NL" ? nl : enUS}
                    // fixedHeight={true}
                />
                <div className="calendarBtns">
                    <a className="reset-btn" onClick={resetDateRange}>
                        {t("Buttons.Reset")}
                    </a>
                    <a
                        className="reset-btn select"
                        onClick={() => {
                            dispatch(
                                setTravelDate({
                                    startDate: isReset
                                        ? ""
                                        : new Date(dateRangeState[0].startDate).toDateString(),
                                    endDate: isReset
                                        ? ""
                                        : new Date(dateRangeState[0].endDate).toDateString(),
                                })
                            );
                            setShowCalendar(false);
                        }}
                    >
                        {t("Buttons.Select")}
                    </a>
                </div>
            </div>
        );

    const getClassFromFilledStarts = (number) => {
        if (
            number >= searchfilters.starRating?.min &&
            number <= searchfilters.starRating?.max
        ) {
            return "filled";
        } else {
            return "";
        }
    };

    return (
        <>
            <div className="quick-filter-search">
                <div
                    id="quickFilter"
                    className={`quick-filter-wrapper ${
                        searchPageComponent ? "search-page" : ""
                    }`}
                >
                    <div className="col-2 col">

                        <div className="rangeslider">
                            <RangeBar
                                lowPrice={searchfilters.defaultPrice.low}
                                highPrice={searchfilters.defaultPrice.high}
                                onChangePriceHandler={handlePriceChange}
                            />
                        </div>
                    </div>

                {/*Home quick Filter*/}
                {!searchPageComponent && (
                    <>
                        <div className="col-2 col stars-range-wrapper">
                            <div className="range-bar">
                                <div className="caption">
                                    <p>{t("Rating")}</p>
                                </div>
                                <div className="range-container">
                                    <p className="range-value lowest">{searchfilters.starRating.min || AppConfig.minStarRating}</p>
                                    <div className="range-slider-holder">
                                        <div className="tiles filter-star-rating">
                                            <div className={getClassFromFilledStarts(1)}>
                                                <FontAwesomeIcon icon={faStar}/>
                                            </div>
                                            <div className={getClassFromFilledStarts(2)}>
                                                <FontAwesomeIcon icon={faStar}/>
                                            </div>
                                            <div className={getClassFromFilledStarts(3)}>
                                                <FontAwesomeIcon icon={faStar}/>
                                            </div>
                                            <div className={getClassFromFilledStarts(4)}>
                                                <FontAwesomeIcon icon={faStar}/>
                                            </div>
                                            <div className={getClassFromFilledStarts(5)}>
                                                <FontAwesomeIcon icon={faStar}/>
                                            </div>
                                        </div>
                                        <div className="outerslider-container">
                                            <TooltipSlider
                                                range
                                                step={1}
                                                min={AppConfig.minStarRating}
                                                max={AppConfig.maxStarRating}
                                                defaultValue={[AppConfig.minStarRating, AppConfig.maxStarRating]}
                                                value={[
                                                    searchfilters.starRating?.min,
                                                    searchfilters.starRating?.max,
                                                ]}
                                                onChange={(value) => {
                                                    if (value[1] === 1) {
                                                        dispatch(setStarRating({min: value[0], max: 2}));
                                                        return;
                                                    }
                                                    dispatch(setStarRating({min: value[0], max: value[1]}));
                                                }}
                                                style={{padding: 0}}
                                                handleStyle={{
                                                    backgroundColor: "#fff",
                                                    borderColor: "white",
                                                    width: "18px",
                                                    height: "18px",
                                                    marginTop: "-6px",
                                                    cursor: "pointer",
                                                    zIndex: 1,
                                                    opacity: "100%",
                                                }}
                                                trackStyle={{
                                                    backgroundColor: "rgb(5, 206, 120)",
                                                }}
                                                activeDotStyle={{
                                                    borderWidth: "0px",
                                                    borderColor: "white",
                                                }}
                                                dotStyle={{height: "20px"}}
                                            />
                                        </div>
                                    </div>
                                    <p className="range-value highest">{searchfilters.starRating.max || AppConfig.maxStarRating}</p>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                    {/*Search page quick filter*/}
                    {searchPageComponent && (
                        <>
                            {" "}
                            <div className="check-in for-large-screen col-1 col">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faCalendar}/>
                                </div>
                                <div
                                    className="text"
                                    onClick={() => {
                                        setShowCalendar(!showCalendar);
                                        setShowTraveler(false);
                                    }}
                                >
                                    <h6 className="darkgray-text">{t("DetailCard.Checkin")}</h6>
                                    <p className="white-text no-margin">
                                        {searchfilters.travelDate.startDate
                                            ? moment(searchfilters.travelDate.startDate).format(
                                                "MMM DD"
                                            )
                                            : t("NoDateSelected")}
                                    </p>

                                    <hr/>

                                    <h6 className="darkgray-text">{t("DetailCard.Checkout")}</h6>
                                    <p className="white-text no-margin">
                                        {searchfilters.travelDate.endDate
                                            ? moment(searchfilters.travelDate.endDate).format("MMM DD")
                                            : t("NoDateSelected")}
                                    </p>
                                </div>
                            </div>
                            <Dropdown
                                className="calendar-dropdown"
                                show={showCalendar}
                                onToggle={(show) => {
                                    setShowTraveler(false);
                                    setShowCalendar(show);
                                }}
                            >
                                <Dropdown.Menu className="calender-menu">
                                    <Calender/>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="for-large-screen col-1 col w-100">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faLocationDot}/>
                                </div>
                                <div className="text">
                                    <div
                                        onClick={() =>
                                            setModals({
                                                ...modals,
                                                destination: !modals.destination,
                                            })
                                        }
                                        className="depart-from"
                                    >
                                        <h6 className="darkgray-text">{t("Destinations")}</h6>
                                        <p className="white-text no-margin">
                                            {searchfilters?.selectedDesireCountry?.keyword ||
                                                t("SearchPage.SelectYour")}
                                        </p>
                                    </div>

                                    <hr/>
                                    <div
                                        onClick={() =>
                                            setModals({
                                                ...modals,
                                                departure: !modals.departure,
                                            })
                                        }
                                        className="depart-from"
                                    >
                                        <h6 className="darkgray-text">
                                            {t("SearchPage.DepatureFrom")}
                                        </h6>
                                        <p className="white-text no-margin">
                                            {searchfilters?.departureFrom?.length === 0 &&
                                                t("SearchPage.SelectDeparture")}
                                            {searchfilters.departureFrom?.map((_departure, index) => (
                                                <span key={index}>
                                                    {`${_departure?.code} ${_departure?.name}`}
                                                    {index !== searchfilters.departureFrom?.length - 1 &&
                                                        ","}{" "}
                        </span>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="date no-border for-small-screen col-1 col">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faCalendar}/>
                                </div>
                                <div
                                    className="text"
                                    onClick={() => {
                                        setShowCalendar(!showCalendar);
                                        setShowTraveler(false);
                                    }}
                                >
                                    <h6 className="darkgray-text">{t("date")}</h6>
                                    <p className="white-text no-margin">
                                        {searchfilters.travelDate.startDate &&
                                        searchfilters.travelDate.endDate
                                            ? moment(searchfilters.travelDate.startDate).format(
                                                "MMMM DD"
                                            ) +
                                            " - " +
                                            moment(searchfilters.travelDate.endDate).format("DD")
                                            : t("NoDateSelected")}
                                    </p>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="traveler col-1 col">
                        <Dropdown
                            show={showTraveler}
                            onToggle={(show) => {
                                setShowTraveler(show);
                                setShowCalendar(false);
                            }}
                            align={{sm: "start"} | {md: "end"} | {lg: "end"}}
                        >
                            <Dropdown.Toggle>
                                <div className="traveler-section">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faUser}/>
                                    </div>
                                    <div className="text ">
                                        <h6 className="darkgray-text">{t("Travelers")}</h6>
                                        <p className="darkgray-text no-margin">{`${
                                            searchfilters.travelers?.length || 0
                                        } ${searchfilters.travelers?.length > 1 ? t("rooms") : t("room")}, ${getTravelersData(searchfilters).total} ${
                                            getTravelersData(searchfilters).total <= 1
                                                ? t("Traveler")
                                                : t("Travelers").toLowerCase()
                                        }`}</p>
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                            <Travelers setShowTraveler={setShowTraveler}/>
                        </Dropdown>
                    </div>

                    {searchPageComponent && (
                        <>
                            <div className="date for-small-screen col-1 col">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faLocationDot}/>
                                </div>
                                <div
                                    className="text depart-from"
                                    onClick={() =>
                                        setModals({
                                            ...modals,
                                            destination: !modals.destination,
                                        })
                                    }
                                >
                                    <h6 className="darkgray-text">{t("Destinations")}</h6>
                                    <p className="white-text no-margin">
                                        {searchfilters?.selectedDesireCountry?.keyword ||
                                            t("SearchPage.SelectYour")}
                                    </p>
                                </div>
                            </div>

                            <div className="date for-small-screen col-1 col">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faLocationDot}/>
                                </div>
                                <div
                                    className="text depart-from"
                                    onClick={() =>
                                        setModals({
                                            ...modals,
                                            departure: !modals.departure,
                                        })
                                    }
                                >
                                    <h6 className="darkgray-text">
                                        {t("SearchPage.DepatureFrom")}
                                    </h6>
                                    <p className="white-text no-margin">
                                        {searchfilters?.departureFrom?.length === 0 &&
                                            t("SearchPage.SelectDeparture")}
                                        {searchfilters.departureFrom?.map((_departure, index) => (
                                            <span key={index}>
                                                {`${_departure?.code} ${_departure?.name}`}
                                                {index !== searchfilters.departureFrom?.length - 1 && ","}{" "}
                                            </span>
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                    {searchPageComponent &&
                        <div
                            className="serach-action green-bg text-center"
                            onClick={routeToSearchScreen}
                        >
                            <a className="white-text fs-18">{t("Buttons.Search")}</a>
                        </div>
                    }
                </div>
                {!searchPageComponent &&
                    <div className="serach-action">
                        <Lottie animationData={Finger} loop={true} className='finger_animation' />
                        <a onClick={routeToSearchScreen} className=""><Lottie animationData={SearchGrow} loop={true} className='search_animation' /></a>
                        <div className="personlize-txt">{t("Buttons.Personlize")}</div>
                    </div>
                }

            </div>
            <DesireCountryModal
                show={modals.destination}
                onHide={() => setModals({...modals, destination: false})}
            />
            <DepartureFromModal
                show={modals.departure}
                onHide={() => setModals({...modals, departure: false})}
                onApplyButtonHandler={(values) => dispatch(setDepartureFrom(values))}
            />
        </>
    );
};

QuickFilter.propTypes = {
    searchPageComponent: PropTypes.bool,
    dateShouldReset: PropTypes.bool,
    setDateShouldReset: PropTypes.func
}

export default QuickFilter;
