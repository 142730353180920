import React, { useEffect, useState } from "react";
import TabTitle from "./TabsTitle";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoginConfig,
  setIsLogin,
  setLoginUser,
} from "../../../redux/slices/loginconfigSlice";
import { setActivityIndicator } from "../../../redux/slices/appconfigSlice";
import { UpdateProfileService } from "../../../services/UpdateProfileService";
import { useTranslation } from "react-i18next";
import { DeleteAccountService } from "../../../services/DeleteAccountService";
import { useNavigate } from "react-router-dom";
import {toast} from "react-toastify";

const AccountTabContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginconfig = useSelector(getLoginConfig);
  const [personalInfo, setPersonalInfo] = useState(null);

  useEffect(() => {
    if (loginconfig.isLogin) {
      setPersonalInfo({
        firstName: loginconfig.loginUser?.firstname,
        lastName: loginconfig.loginUser?.lastname,
        // phoneNumber: "",
        email: loginconfig.loginUser?.email,
        // dob: "",
      });
    }
  }, []);

  useEffect(() => {}, [loginconfig.locale]);

  const onPersonalInfoChange = (e, type) => {
    e.preventDefault();
    setPersonalInfo({ ...personalInfo, [type]: e.target.value });
  };

  const handleOnUpdate = async (e) => {
    e.preventDefault();
    try {
      const params = {
        Guid: loginconfig.loginUser.guid,
        UpdateContent: "Name",
        NewFirstName: personalInfo.firstName,
        NewLastName: personalInfo.lastName,
      };
      if (loginconfig.isLogin) {
        dispatch(setActivityIndicator(true));
        // console.log(params);

        await UpdateProfileService(params);
        dispatch(
          setLoginUser({
            ...loginconfig.loginUser,
            firstname: params.NewFirstName,
            lastname: params.NewLastName,
          })
        );
        dispatch(setActivityIndicator(false));
        toast.success(`${t("infoUpdated")}`);
      }
    } catch (error) {
      dispatch(setActivityIndicator(false));
    }
  };

  const handleLogout = () => {
    dispatch(setIsLogin(false));
    dispatch(setLoginUser({}));
    localStorage.removeItem("auth_token");
    navigate("/me/settings");
  };

  const deleteAccount = () => {
    if (!loginconfig.isLogin) return;
    if (window.confirm(t("DeleteAccount.Confirm"))) {
      const params = {
        Guid: loginconfig?.loginUser?.guid,
      };
      dispatch(setActivityIndicator(true));
      DeleteAccountService(params)
        .then(() => {
          dispatch(setActivityIndicator(false));
          handleLogout();
        })
        .catch(() => {
          dispatch(setActivityIndicator(false));
        });
    } else {
      console.log("Cancel");
    }
  };

  return (
    <>
      <TabTitle title="Account"></TabTitle>
      <div className="tabs-inner-content">
        <div className="account-form-holder">
          <form>
            <div className="row">
              <div className="col-12">
                <h6 className="green-text fs-16 font-noto">
                  {t("UserTab.PersonalInfo")}
                </h6>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-style" htmlFor="name">
                    {t("UserTab.FirstName")}
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder={t("Placeholders.EnterFName")}
                    className="input-style"
                    value={personalInfo?.firstName}
                    onChange={(e) => onPersonalInfoChange(e, "firstName")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-style" htmlFor="real-name">
                    {t("UserTab.LastName")}
                  </label>
                  <input
                    type="text"
                    name="real-name"
                    placeholder={t("Placeholders.EnterLName")}
                    className="input-style"
                    value={personalInfo?.lastName}
                    onChange={(e) => onPersonalInfoChange(e, "lastName")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-style" htmlFor="email">
                    {t("Email")}
                  </label>
                  <input
                    readOnly
                    type="email"
                    name="email"
                    placeholder={t("Placeholders.EnterYourEmail")}
                    className="input-style"
                    value={personalInfo?.email}
                    onChange={(e) => onPersonalInfoChange(e, "email")}
                  />
                </div>
              </div>
              {/*<div className="col-md-6">*/}
              {/*  <div className="form-group">*/}
              {/*    <label className="label-style" htmlFor="dob">*/}
              {/*      {t("Placeholders.DOB")}*/}
              {/*    </label>*/}
              {/*    <input*/}
              {/*      type="text"*/}
              {/*      name="dob"*/}
              {/*      placeholder="MM -DD - YYYY"*/}
              {/*      className="input-style"*/}
              {/*      value={personalInfo?.dob}*/}
              {/*      onChange={(e) => onPersonalInfoChange(e, "dob")}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="col-12">
                <div className="form-group">
                  <a className="form-action" onClick={handleOnUpdate}>
                    {t("Buttons.UpdateProfile")}
                  </a>
                </div>
              </div>
            </div>
          </form>
          <div className="row account-delete">
            <div className="col-md-12 text-center">
              <div className="mb-4">{t("DeleteAccount.Description")}</div>
              <button className="delete-btn" onClick={deleteAccount}>
                {t("DeleteAccount.Button")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountTabContent;
