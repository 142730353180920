import {createSlice} from "@reduxjs/toolkit";
import AppConfig from "../../helpers/config";

const initialState = {
    selectedPackage: null,
    selectedHotel: null,
    selectedHotelDetail: [],
    paginatedRoomTypes: [],
    roomFlights: [],
    paginatedRoomFlights: [],
    defaultPrice: {
        low: 0,
        high: 2500,
    },
    travelDate: {
        startDate: "",
        endDate: "",
    },
    travelTime: null,
    travelers: [
        {
            child: 0,
            adult: 2,
            infants: 0,
        },
    ],
    departureFrom: [],
    selectedDesireCountry: null,
    appFilters: [
        {
            _id: "checkbox-1-ECONOMY-airline_class",
            id: 1,
            key: "airline_class",
            text: "ECONOMY",
            value: "ECONOMY",
        },
        {
            _id: "checkbox-1-Anytime-arrival_time",
            id: 1,
            key: "arrival_time",
            text: "Anytime"
        },
        {
            _id: "checkbox-1-Anytime-departure_time",
            id: 1,
            key: "departure_time",
            text: "Anytime"
        }
    ],
    listingGuid: null,
    numberOfStops: 0,
    starRating: {min: AppConfig.minStarRating, max: AppConfig.minStarRating},
    flightCabinClass: "ECONOMY",
    selectedFavoriteHotel: null
};

export const searchFilterSlice = createSlice({
    name: "searchfilters",
    initialState,
    reducers: {
        resetSearchFiltersState: () => initialState,
        setSelectedPackage: (state, action) => {
            state.selectedPackage = action.payload;
        },
        setSelectedHotel: (state, action) => {
            let payload = action.payload;
            if (state.selectedHotel !== null && payload) {
                payload = {...state.selectedHotel, ...payload};
            }
            state.selectedHotel = payload;
        },
        setSelectedHotelDetail: (state, action) => {
            state.selectedHotelDetail = action.payload;
        },
        setTravelDate: (state, action) => {
            state.travelDate = action.payload;
        },
        setTravelers: (state, action) => {
            state.travelers = action.payload;
        },
        setDepartureFrom: (state, action) => {
            state.departureFrom = action.payload;
        },
        setPriceRangeValue: (state, action) => {
            state.defaultPrice = action.payload;
        },
        setRoomFlights: (state, action) => {
            state.roomFlights = action.payload;
        },
        setFlightCabinClass: (state, action) => {
            state.flightCabinClass = action.payload;
        },
        setDesireCountry: (state, action) => {
            state.selectedDesireCountry = action.payload;
        },
        setTravelTime: (state, action) => {
            state.travelTime = action.payload;
        },
        setAppFilters: (state, action) => {
            state.appFilters = action.payload;
        },
        setListingGuid: (state, action) => {
            state.listingGuid = action.payload;
        },
        setNumberOfStops: (state, action) => {
            state.numberOfStops = action.payload;
        },
        setStarRating: (state, action) => {
            state.starRating = action.payload;
        },
        setPaginatedRoomTypes: (state, action) => {
            state.paginatedRoomTypes = action.payload;
        },
        setPaginatedRoomFlights: (state, action) => {
            state.paginatedRoomFlights = action.payload;
        },
        setSelectedFavoriteHotel: (state, action) => {
            state.selectedFavoriteHotel = action.payload;
        },
    },
});

export const getSearchFilters = (state) => state.searchfilters;

// Action creators are generated for each case reducer function
export const {
    resetSearchFiltersState,
    setSelectedPackage,
    setTravelDate,
    setTravelers,
    setDepartureFrom,
    setPriceRangeValue,
    setSelectedHotel,
    setSelectedHotelDetail,
    setRoomFlights,
    setFlightCabinClass,
    setDesireCountry,
    setTravelTime,
    setAppFilters,
    setListingGuid,
    setNumberOfStops,
    setStarRating,
    setPaginatedRoomTypes,
    setPaginatedRoomFlights,
    setSelectedFavoriteHotel
} = searchFilterSlice.actions;

export default searchFilterSlice.reducer;
