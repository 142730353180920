import React from "react";
import BlogImage from "../../assets/images/blog-image.png";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/pro-light-svg-icons";

const BlogCardGrid = () => {
  return (
    <div className="blog-card-grid">
      <div className="blog-card-wrapper">
        <div className="blog-image">
          <img src={BlogImage} />
        </div>
        <div className="blog-content">
          <h2 className="heading heading-2">
            With Genius you will find top destinations
          </h2>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
            commodo ligula eget dolor. Aenean massa. Cum{" "}
            <a href="#">sociis natoque penatibus</a> et magnis dis parturient
            montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,
            pellentesque eu, pretium quis, sem.rabitur ullamcorper ultricies
            nisi. Nam eget dui.
          </p>
          <a href="#" className="read-more">
            Less Meee
            <FontAwesomeIcon icon={faAngleRight} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BlogCardGrid;
