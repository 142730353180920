import React, {useState} from "react";
import {Dropdown} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    getSearchFilters,
    setTravelers,
} from "../../../redux/slices/searchFilterSlice";
import "./style.scss";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-light-svg-icons";
import {faPlus, faMinus} from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import {toast} from "react-toastify";

const Travelers = ({setShowTraveler}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const searchfilters = useSelector(getSearchFilters);
    const [localTravelers, setLocalTravelers] = useState(searchfilters.travelers);

    const counterPlus = (index, type) => {
        let _travelers = [...localTravelers].map((traveler, i) => {
            if (index === i) {
                let counter = traveler[type];
                return {...traveler, [type]: counter + 1};
            }
            return traveler;
        });

        if (checkIfTravelsHasMax(_travelers) > 6) {
            return toast.info(t("maxTravelerDescription"));
        }

        setLocalTravelers(_travelers)
    };

    const counterMinus = (index, type) => {
        if (localTravelers[index][type] > 0) {
            let _travelers = [...localTravelers].map((traveler, i) => {
                if (index === i) {
                    let counter = traveler[type];
                    if (counter === 1 && type === "adult") return traveler;
                    return {...traveler, [type]: counter - 1};
                }
                return traveler;
            });

            if (checkIfTravelsHasMax(_travelers) > 6) {
                return toast.info(t("maxTravelerDescription"));
            }

            setLocalTravelers(_travelers);
        }
    };

    const checkIfTravelsHasMax = _rooms => {
        return _rooms.reduce((total, room) => {
            return total + room.child + room.adult + room.infants;
        }, 0);
    };

    return (
        <Dropdown.Menu>
            {localTravelers?.map((traveler, index) => {
                return (
                    <div key={index}>
                        <div className="room-title">
                            <p className="d-titlle">
                                {t("DetailCard.Room")} {index + 1}
                            </p>
                            {index !== 0 && (
                                <a
                                    onClick={() =>
                                        setLocalTravelers(
                                            [...localTravelers].filter(
                                                (f, i) => i !== index
                                            )
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={faTimes}/>
                                </a>
                            )}
                        </div>
                        <li>
                            <div className="caption">
                                <p>{t("Adults")}</p>
                            </div>
                            <div className="qty">
                                <a
                                    className="qty-action"
                                    onClick={() => counterMinus(index, "adult")}
                                >
                                    <FontAwesomeIcon icon={faMinus}/>
                                </a>
                                <p className="qty-val">{traveler?.adult}</p>
                                <a
                                    className="qty-action"
                                    onClick={() => counterPlus(index, "adult")}
                                >
                                    <FontAwesomeIcon icon={faPlus}/>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div className="caption">
                                <p>{t("ChildYear")}</p>
                            </div>
                            <div className="qty">
                                <a
                                    className="qty-action"
                                    onClick={() => counterMinus(index, "child")}
                                >
                                    <FontAwesomeIcon icon={faMinus}/>
                                </a>
                                <p className="qty-val">{traveler?.child}</p>
                                <a
                                    className="qty-action"
                                    onClick={() => counterPlus(index, "child")}
                                >
                                    <FontAwesomeIcon icon={faPlus}/>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div className="caption">
                                <p>{t("InfantsYear")}</p>
                            </div>
                            <div className="qty">
                                <a
                                    className="qty-action"
                                    onClick={() => counterMinus(index, "infants")}
                                >
                                    <FontAwesomeIcon icon={faMinus}/>
                                </a>
                                <p className="qty-val">{traveler?.infants}</p>
                                <a
                                    className="qty-action"
                                    onClick={() => counterPlus(index, "infants")}
                                >
                                    <FontAwesomeIcon icon={faPlus}/>
                                </a>
                            </div>
                        </li>
                    </div>
                );
            })}

            <div className="addmore-action">
                <a
                    onClick={() =>{
                        const newRooms = [...localTravelers, {child: 0, adult: 1, infants: 0}];
                        if (checkIfTravelsHasMax(newRooms) > 6) {
                         return toast.info(t("maxTravelerDescription"));
                        }

                        if (newRooms.length > 3) {
                            return toast.info(t("maxTravelerRoomsDescription"));
                        }
                        setLocalTravelers([...localTravelers, {child: 0, adult: 1, infants: 0}])
                    }}
                >
                    {t("AddRoom")}
                </a>
                <a onClick={() => {
                    dispatch(setTravelers(localTravelers));
                    setShowTraveler(false);
                }}>{t("Apply")}</a>
            </div>
        </Dropdown.Menu>
    );
};

Travelers.propTypes = {
    setShowTraveler:  PropTypes.func
}


export default Travelers;
