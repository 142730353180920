import React, {useEffect} from "react";
import Slider from "react-slick";
import "./style.scss";
import {useSelector} from "react-redux";
import {getAppConfig} from "../../redux/slices/appconfigSlice";
import PackageCard from "./PackageCard";
import PropTypes from "prop-types";

const PackageSlider = () => {
    const appconfig = useSelector(getAppConfig);
    useEffect(() => {
    }, [appconfig.offers]);

    const settings = {
        // className: "center",
        dots: false,
        centerMode: false, // In case of true, Make the className: 'center'
        infinite: true,
        // centerPadding: "150px",
        slidesToShow: 5,
        slidesToScroll: 1,
        swipeToSlide: true,
        speed: 200,
        initialSlide: 3,
        arrows: true,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        responsive: [
            // {
            //   breakpoint: 2200,
            //   settings: {
            //     slidesToShow: 6,
            //     centerPadding: "50px",
            //   },
            // },
            // {
            //   breakpoint: 2000,
            //   settings: {
            //     slidesToShow: 6,
            //     centerPadding: "50px",
            //   },
            // },
            {
                breakpoint: 1530,
                settings: {
                    slidesToShow: 5,
                    // centerPadding: "0px",
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    // centerPadding: "0px",
                },
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    // centerPadding: "0px",
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    centerPadding: "0px",
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    centerPadding: "100px",
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: "50px",
                },
            },
        ],
    };

    function SampleNextArrow({onClick}) {
        return (
            <span
                className="fas fa-long-arrow-alt-right slick-arrow arrow-right"
                onClick={onClick}
            ></span>
        );
    }

    function SamplePrevArrow({onClick}) {
        return (
            <span
                className="fas fa-long-arrow-alt-left slick-arrow arrow-left"
                onClick={onClick}
            ></span>
        );
    }

    SampleNextArrow.propTypes = {
        onClick: PropTypes.func,
    }

    SamplePrevArrow.propTypes = {
        onClick: PropTypes.func,
    }

    return (
        <Slider className={'slider-outside-arrows'} {...settings}>
            {appconfig?.packageTypes?.map((_package, index) => (
                <PackageCard key={index} package={_package}/>
            ))}
        </Slider>
    );
};

export default PackageSlider;
